<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" class="pa-0"><StepperLayout /></v-col>

    <v-col cols="12" class="d-flex flex-column mt-15 align-center">
      <v-img
        contain
        max-height="110"
        max-width="110"
        src="/insuranceIcons/check.svg"
      ></v-img>
      <span class="font-size-title font-weight-bold mt-5 text-center purple-dark--text" v-html="$t('orderPlaced.thankYou')"></span
      ><span
        class="full-width text-center px-5 mt-5 purple-dim--text"
        style="max-width: 810px"
        >{{ $t('orderPlaced.yourRequestHasBeenSent') }}</span
      >
      <div class="bottom-btn mt-10 d-flex justify-center align-center">
        <v-btn
          text
          :large="$vuetify.breakpoint.mdAndUp"
          @click="goToProsperty"
          class="petition-btn-border primary mb-10 text-none rounded-xl"
          >{{ $t('orderPlaced.backToProsperty') }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import StepperLayout from '@/components/layouts/StepperLayout';
import { PROSPERTY_LETTING_URL } from '@/config/app';

export default {
  name: 'OrderPlaced',
  components: { StepperLayout },

  methods: {
    goToProsperty() {
      window.location.href = PROSPERTY_LETTING_URL;
    },
  },
};
</script>
